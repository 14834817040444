import {useEffect} from "react";
import anime from 'animejs/lib/anime.es.js';

var numberOfParticules = 12;
var colors = ['#FBDD5B', '#76E8B2', '#F07E6E', '#00E3FF', '#C3AEFF', '#F098F2'];

function setCanvasSize(canvasEl) {
  canvasEl.width = window.innerWidth * 2;
  canvasEl.height = window.innerHeight * 2;
  canvasEl.style.width = window.innerWidth + 'px';
  canvasEl.style.height = window.innerHeight + 'px';
  canvasEl.getContext('2d').scale(2, 2);
}

function setParticuleDirection(p) {
  var angle = anime.random(0, 360) * Math.PI / 180;
  var value = anime.random(50, 340);
  var radius = [-1, 1][anime.random(0, 1)] * value;
  return {
    x: p.x + radius * Math.cos(angle),
    y: p.y + radius * Math.sin(angle)
  }
}

function createParticule(ctx, x, y) {
  var p = {};
  p.x = x;
  p.y = y;
  p.color = colors[anime.random(0, colors.length - 1)];
  p.radius = anime.random(16, 42);
  p.endPos = setParticuleDirection(p);
  p.draw = function () {
    ctx.beginPath();
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
    ctx.fillStyle = p.color;
    ctx.fill();
  }
  return p;
}

function renderParticule(anim) {
  for (var i = 0; i < anim.animatables.length; i++) {
    anim.animatables[i].target.draw();
  }
}

function animateParticules(ctx, x, y) {
  var particules = [];
  for (var i = 0; i < numberOfParticules; i++) {
    particules.push(createParticule(ctx, x, y));
  }
  anime.timeline().add({
    targets: particules,
    x: function (p) {return p.endPos.x;},
    y: function (p) {return p.endPos.y;},
    radius: 0.1,
    duration: anime.random(2500, 3800),
    easing: 'easeOutExpo',
    update: renderParticule
  });
}

// Hook
export const useFireworkAnimation = () => {
  const isClient = typeof window === "object";

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const canvasEl = document.querySelector('.fireworks');
    const confettiEl = document.getElementById("confetti");
    const ctx = canvasEl.getContext('2d');
    setCanvasSize(canvasEl);

    const handleResize = () => {
      setCanvasSize(canvasEl);
    }

    window.addEventListener("resize", handleResize);

    var render = anime({
      duration: Infinity,
      update: function () {
        ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      }
    });

    render.play();
    const rect = confettiEl.getBoundingClientRect();
    var startX = window.innerWidth / 2;
    var startY = rect.bottom;
    animateParticules(ctx,
      startX,
      startY);

    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]);
};

