import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-scroll";
import styled from "styled-components";
import {useFireworkAnimation} from '../../../hooks/useFirework';

import imgJen from "../../../assets/image/before-after/Jen.svg";
import imgPan from "../../../assets/image/before-after/Pan.svg";
import imgArie from "../../../assets/image/before-after/Arie.svg";
import imgTheo from "../../../assets/image/before-after/Theo.svg";
import imgSaleh from "../../../assets/image/before-after/Saleh.svg";
import imgAlicia from "../../../assets/image/before-after/Alicia.svg";


const HeroStyled = styled.div`
`;


const Hero = () => {
  useFireworkAnimation();

  return (
    <>
      <HeroStyled
        className="hero-area position-relative pb-13 pt-24 pt-lg-32 pb-lg-28 d-lg-flex align-items-center lg:min-h-vh-100"
      >
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg="12" xl="12" data-aos="fade-up" data-aos-duration="1000">
              <div className="hero-content section-title text-center">
                <h1 className="gr-text-6 gr-text-md-3 font-weight-bold mb-12">
                  We asked our customers
                  <br />
                  what changed for <span id="confetti" className="highlighted1" style={{backgroundPosition: "0 bottom"}}>the better since using Camelo.</span>
                  <br />
                  Here’s what they said.
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          alt="Customer"
          src={imgJen}
          data-aos="fade-up"
          data-aos-duration="2500"
          className="before-after-faces its-jen"
        />
        <img
          alt="Customer"
          src={imgPan}
          data-aos="fade-up"
          data-aos-duration="500"
          className="before-after-faces its-pan"
        />
        <img
          alt="Customer"
          src={imgTheo}
          data-aos="fade-up"
          data-aos-duration="1500"
          className="before-after-faces its-theo"
        />
        <img
          alt="Customer"
          src={imgArie}
          data-aos="fade-up"
          data-aos-duration="2500"
          className="before-after-faces its-arie"
        />
        <img
          alt="Customer"
          src={imgAlicia}
          data-aos="fade-up"
          data-aos-duration="1000"
          className="before-after-faces its-alicia"
        />
        <img
          alt="Customer"
          src={imgSaleh}
          data-aos="fade-up"
          data-aos-duration="2000"
          className="before-after-faces its-saleh"
        />
        <canvas className="fireworks" style={{position: 'absolute', top: 0, left: 0, zIndex: -1000}}></canvas>
        <div className="gr-abs-bx-center mx-auto mt-13 text-center">
          <div data-aos="fade-down" data-aos-delay="2000" data-aos-easing="ease-in-out-quart" data-aos-duration="800" className="gr-text-12" >READ NOW</div>
          <div>
            <Link
              to="elPeopleSaid"
              spy={true}
              smooth="easeInOutCubic"
              offset={-80}
              duration={1000}
              className="circle-40 border border-black goto mx-auto mt-4"
              css={`
            cursor: pointer;
          `}
            >
              <i className="fas fa-chevron-down text-black mx-auto gr-text-8"></i>
            </Link>
          </div>
        </div>
      </HeroStyled>
    </>
  );
};

export default Hero;

