import React, {useState} from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import imgArrow from "../../../assets/image/before-after/arrow.svg";
import imgC from "../../../assets/image/customers/Amelie White.png";
import imgStars from "../../../assets/image/svg/five-stars-rating.svg";

// general manager
// owner
// co-owner
// manager

const BEFORE_ITEMS = [
  {
    img: imgC,
    brandName: "Savior Care",
    position: "owner",
    fname: "Jerad",
    lname: "Hagenes",
    whattheysaid: "I planned my employees' shifts on papers and notebooks. The work schedule was always a mess, full of strike-throughs and messy handwriting. Changes were so difficult.",
  },
  {
    img: imgC,
    brandName: "Pristine Pros Cleaning",
    position: "owner",
    fname: "Laurence",
    lname: "Schneider",
    whattheysaid: "I felt like half of the time managing my business I spent on scheduling shifts.",
  },
  {
    img: imgC,
    brandName: "Diamond Ridge Construction	",
    position: "co-owner",
    fname: "Thomas",
    lname: "Bailey",
    whattheysaid: "So many emails back and forth for sick-leave and time-off requests. So difficult to manage who's on and who's off. So much hassle for keeping track of things.",
  },
  {
    img: imgC,
    brandName: "El Pirata Porch Restaurant",
    position: "general manager",
    fname: "Alex",
    lname: "Kolly",
    whattheysaid: "I hesitated to use a digital method to manage my team because I wasn't a high-tech person. I was wrong. I could use paper and pen for drawing, writing, not scheduling.",
  },
  {
    img: imgC,
    brandName: "Atomic Liquors",
    position: "owner",
    fname: "Solbritt",
    lname: "Olofsson",
    whattheysaid: "I used a whiteboard and paper before Camelo. I quickly realized that using those made me miserable. I spent hours crafting schedules for my staff. I could use those hours for something better.",
  },
  {
    img: imgC,
    brandName: "Fonseca Burger",
    position: "owner",
    fname: "Nadia",
    lname: "Alfaro",
    whattheysaid: "Our team had a difficult time keeping track of shifts, available members, and tasks. We used several apps to manage these things, but none of them made our lives easier.",
  },
  {
    img: imgC,
    brandName: "Grove Link Clinic",
    position: "co-owner",
    fname: "Dr. Gunnar",
    lname: "Erdman",
    whattheysaid: "Checking attendance used to be tedious and tiring for me. Keeping track of the hours and data took me hours whether I use pen and paper or a spreadsheet.",
  },
  {
    img: imgC,
    brandName: "Sesto Italiano Restaurant",
    position: "co-owner",
    fname: "Donatella",
    lname: "Bellini",
    whattheysaid: "I had to hunt for available employees, literally, whenever there were time-off requests. I tried to call and text my staff like crazy to find a good match for the shifts.",
  },
  {
    img: imgC,
    brandName: "Samurai Clearning",
    position: "owner",
    fname: "Charlotte",
    lname: "Lewis",
    whattheysaid: "I spent a considerable amount of money on paper time cards and paper schedules. Every time there were changes, I had to print new ones or find a way to notify my staff. Exhausting and time-consuming.",
  },
  {
    img: imgC,
    brandName: "Onyx General Contractors",
    position: "owner",
    fname: "Andy",
    lname: "King",
    whattheysaid: "My employee schedule was always chaotic. I had to dig through piles of papers and made endless calls almost every day to find the right people for vacant shifts.",
  },
  {
    img: imgC,
    brandName: "Ace & Hammer Builders	",
    position: "manager",
    fname: "Jade",
    lname: "Roberts",
    whattheysaid: "Time-off and leave requests came up all the time. I had to find the right substitutes, contact them, and ask if they could step in. If I were lucky, they'd agree. Most of the time, they didn't. That cycle went on until someone finally accepted. That frustrated the hell out of me.",
  },
  {
    img: imgC,
    brandName: "Dust & Shine Co.",
    position: "owner",
    fname: "Will",
    lname: "Morris",
    whattheysaid: "I used to distribute paper schedules to my staff every week. If a staff decided to take a day off, I had to notify all of the others. It was time-consuming and annoying.",
  },
  {
    img: imgC,
    brandName: "Royal Maid Cleaning Service",
    position: "owner",
    fname: "Melissa",
    lname: "Collins",
    whattheysaid: "I had to be very careful not to overschedule my staff. Paper time cards, counting total work hours and stuff. I did them manually. Urgh.",
  },
  {
    img: imgC,
    brandName: "Cordial Care",
    position: "owner",
    fname: "Rocio",
    lname: "Nitzsche",
    whattheysaid: "We had 20 members in our team. Every time the schedule was adjusted, I had to update the changes via email and phone to 20 people, and made sure they'd received the updates. When they didn't reply, I had to call them. That was certainly not a great way to manage our schedule.",
  },
  {
    img: imgC,
    brandName: "Nurture And Nurse",
    position: "co-owner",
    fname: "Dr. Dasia",
    lname: "Crist",
    whattheysaid: "It was difficult to assign tasks to my staff and keep track of who's doing what. I'd never really had time to organize them. Sometimes I wrote the tasks and names down on paper, other times I kept them on my phone. I had to literally dig through every document and note, digitally and physically, whenever I needed certain information.",
  },
  {
    img: imgC,
    brandName: "Healing Equilibrium Clinic",
    position: "co-owner",
    fname: "Keanu",
    lname: "Grady",
    whattheysaid: "I struggled with time-off requests and attendance a lot. I once forgot to find a replacement for a vacant shift. The workflow was messed up, the customers were dissatisfied, the available employees were exhausted, and I felt terrible.",
  },
];

const AFTER_ITEMS = [
  {
    img: imgC,
    brandName: "Lee Harvey’s",
    position: "co-owner",
    fname: "Seth",
    lname: "Smith",
    whattheysaid: "Finally, we don't have to be drowned in endless piles of paper schedules anymore. My team members are notified whenever there are changes in the schedule. No one misses their shifts anymore.",
  },
  {
    img: imgC,
    brandName: "J&D Cafe",
    position: "owner",
    fname: "Jared",
    lname: "Lebsack",
    whattheysaid: "All of my staff's shifts, tasks, and time-off requests are in one place—my phone. Managing my staff has become easier with just a few swipes and touches on the screen.",
  },
  {
    img: imgC,
    brandName: "Clippings N Cuts",
    position: "owner",
    fname: "Ravi",
    lname: "DeRossi",
    whattheysaid: "I can easily see who's on and who's off, who's available and who's not.",
  },
  {
    img: imgC,
    brandName: "Lavish Health Clinic",
    position: "general manager",
    fname: "Kate",
    lname: "Johnston",
    whattheysaid: "I no longer dread looking for shift replacements. Camelo recommends who's appropriate for the shift, and I simply need to choose a name.",
  },
  {
    img: imgC,
    brandName: "The Snippers",
    position: "owner",
    fname: "Ashley",
    lname: "S.",
    whattheysaid: "With the help of Camelo, it's so easy to keep everyone updated on their new schedules.",
  },
  {
    img: imgC,
    brandName: "Healthstone Community Hospital",
    position: "general manager",
    fname: "Kenny",
    lname: "Price",
    whattheysaid: "My staff can now update their availability, claim shifts, and swap shifts quickly. Scheduling is no longer a burden to me.",
  },
  {
    img: imgC,
    brandName: "Smart n Savvy Contractors",
    position: "co-owner",
    fname: "Stefan",
    lname: "Heller",
    whattheysaid: "I tried several tools to manage my staff and keep our business running smoothly, but none of them matched my needs. I came across Camelo one day, decided to give it a try, and I haven't looked for anything else ever since. Everything I need is now in one place.",
  },
  {
    img: imgC,
    brandName: "British Hairways",
    position: "owner",
    fname: "Sameera",
    lname: "Mangal",
    whattheysaid: "My employees used to send me time-off and leave requests via email. Communication was slow and inefficient. Different emails floated everywhere. Camelo allows me to approve and deny those requests right on my phone.",
  },
  {
    img: imgC,
    brandName: "Safari Lawn Care",
    position: "owner",
    fname: "Dhiraj",
    lname: "Dalal",
    whattheysaid: "No shifts are forgotten, no tasks are unfinished, and I always know what my staff are doing.",
  },
  {
    img: imgC,
    brandName: "Charleston Clinics",
    position: "GM",
    fname: "Seth",
    lname: "Johnston",
    whattheysaid: "I like how my staff can open the app and view their shifts right away.",
  },
  {
    img: imgC,
    brandName: "TurfClips Lawn Care",
    position: "co-owner",
    fname: "Fannie",
    lname: "Kessler",
    whattheysaid: "I often have too much on my plate. Camelo takes scheduling and task assigning off my plate.",
  },
  {
    img: imgC,
    brandName: "Premier Home Consulting",
    position: "owner",
    fname: "Kristian",
    lname: "Dach",
    whattheysaid: "Shifts and tasks are now so well-organized. No more papers, cross-marks, strike-throughs!",
  },
  {
    img: imgC,
    brandName: "Anita Haircut",
    position: "owner",
    fname: "Aditya",
    lname: "Gerhold",
    whattheysaid: "We don't have to jump between spreadsheets, group chats, and emails to know who's working and who's not anymore.",
  },
  {
    img: imgC,
    brandName: "Sharper Shop",
    position: "owner",
    fname: "Callum",
    lname: "Campbell",
    whattheysaid: "We've saved lots of time on managing availability and time-off, as well as planning shifts and assigning tasks.",
  },
  {
    img: imgC,
    brandName: "Base Mountain Construction",
    position: "general manager",
    fname: "Ivah",
    lname: "Hammer",
    whattheysaid: "I love that we can contact each other and view our shifts without jumping from one app to another.",
  },
  {
    img: imgC,
    brandName: "Rose Valley Medical Center",
    position: "general manager",
    fname: "Frau Dr. Saskia",
    lname: "Rapp B.A.",
    whattheysaid: "I can now ask my staff to clean the kitchen, restock the ingredients, and update the menu right on my phone. My staff are notified and reminded of their tasks via mobile notifications, and they can always access their task list in case they forget what they need to do.",
  },
  {
    img: imgC,
    brandName: "Sunshine Build Group",
    position: "co-owner",
    fname: "Gunner",
    lname: "Fritsch",
    whattheysaid: "We can now message team members quickly without going through a long and tedious chain of emails.",
  },
  {
    img: imgC,
    brandName: "Charleston Clinics",
    position: "owner",
    fname: "Dr. Makayla",
    lname: "Murphy",
    whattheysaid: "Our team communication is enhanced by 75% since using Camelo. Everyone can receive messages instantly and respond promptly.",
  },
  {
    img: imgC,
    brandName: "Green Concrete Builder",
    position: "owner",
    fname: "Wyman",
    lname: "Dare",
    whattheysaid: "I no longer face the pressure of crafting the appropriate emails with the right words. From asking my coworkers to cover each other's shifts to sending time-off requests to my manager, Camelo makes them all easier.",
  },
];

const BubbleTalk = ({position, brandName, fname, lname, whattheysaid, showStars}) => {
  return (
    <>
      <Card className="p-lg-3 p-xs-1 mb-8 mb-lg-16 text-left gr-hover-shadow-3">
        <blockquote className="blockquote mb-0 card-body">
          {showStars && <div>
            <img src={imgStars} style={{width: "5rem"}} />
          </div>}
          <p className="text-black pt-4"> {whattheysaid} </p>
          <footer className="blockquote-footer">
            <div className="d-flex align-items-center justify-content-between">
              <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                <div><strong>
                  {brandName}
                </strong></div>
                <div>
                  {fname + " " + lname}
                  <span> ({position})</span>
                </div>
              </div>
              {/* <img className="zoomable rounded-circle h-auto overflow-hidden" style={{backgroundColor: "#C3AEFF", width: "40px"}} width="40" src={img} alt="" /> */}
            </div>
          </footer>
        </blockquote>
      </Card>
    </>
  );
}

const reviewPerPage = 4;
const TOTAL_REVIEWS = 142;

const PeopleAreTalking = () => {
  const [totalReviews, setTotalReviews] = useState(TOTAL_REVIEWS - reviewPerPage);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [beforeItems, setBeforeItems] = useState(BEFORE_ITEMS.slice(0, reviewPerPage));
  const [afterItems, setAfterItems] = useState(AFTER_ITEMS.slice(0, reviewPerPage));

  const loadMoreReviews = () => {
    if (totalReviews < 122) {
      setShowLoadMore(false);
    }
    let ll = TOTAL_REVIEWS - totalReviews + reviewPerPage;
    setBeforeItems(BEFORE_ITEMS.slice(0, ll));
    setAfterItems(AFTER_ITEMS.slice(0, ll));
    setTotalReviews(totalReviews - reviewPerPage);
  };

  return (
    <>
      <div className="pt-18 bg-default-9" id="elPeopleSaid">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="5" className="text-center">
              <h3 className="pb-10">Before switching</h3>
              {beforeItems.map((item, index) => <BubbleTalk key={"before" + index} {...item} />)}
            </Col>
            <Col xs="0" md="2" className="overflow-hidden d-none d-md-block">
              <img
                src={imgArrow}
                alt=""
                className="mx-auto w-85"
              />
              <div className="mx-auto h-100 before-after-vl"></div>
            </Col>
            <Col xs="12" md="5" className="text-center">
              <h3 className="pb-10"><span className="highlighted2 pb-10">After switching</span></h3>
              {afterItems.map((item, index) => <BubbleTalk key={"after" + index} {...item} showStars={true} />)}
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-center pb-8 pb-lg-12">
              <div className="gr-text-10">
                {showLoadMore &&
                  <div><Button variant="light" onClick={loadMoreReviews}>Read More...</Button></div>}
                <div className="py-3 px-6 rounded-20 d-inline-block text-muted">We've got {totalReviews} more quotes like these</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PeopleAreTalking;
